import React from "react";
import "./loader.css";

const PageLoader = () => {
  return (
    <div class="loader">
      <div class="cell d-0"></div>
      <div class="cell d-1"></div>
      <div class="cell d-2"></div>

      <div class="cell d-1"></div>
      <div class="cell d-2"></div>

      <div class="cell d-2"></div>
      <div class="cell d-3"></div>

      <div class="cell d-3"></div>
      <div class="cell d-4"></div>
    </div>
  );
};

export default PageLoader;
