import React, { useState } from "react";
import classes from "./user.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SortIcon } from "../../Assets/Icons/icons";
import UserTableRows from "./userTableRows";
import { sampleUserItems } from "../../Utilities/commonConstants";
import { useSelector } from "react-redux";

const UserTable = ({checkedUsers,setCheckedUsers}) => {
  const { error, customersList } = useSelector((state) => state.customerData);




  function handleCheckUsers(e,datas) {
    console.log("datas =",datas)
    const {checked} = e.target;
    if (checked) {
      let existing_ids = [...checkedUsers];
      existing_ids.push(datas.id);
      setCheckedUsers(existing_ids);
    } else {
      let existing_ids = [...checkedUsers];
      let filtering_ids = existing_ids.filter((el)=>el != datas.id);
      setCheckedUsers(filtering_ids);
    }
  }

  return (
    <Table>
      <TableHead className={classes.TableHeaderRow}>
        <TableRow>
          <TableCell flex={1} style={{ minWidth: 72 }}></TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>User Name</span>
            {/* <SortIcon /> */}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Mobile Number</span>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Email ID</span>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 145 }}>
            <span>Registration Date</span>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Location</span>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Last login</span>
            {/* <SortIcon /> */}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Status</span>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <span>Action</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody classeName={classes.TableBody}>
        {customersList?.map((item, index) => {
          return <UserTableRows key={index} item={item} handleCheckUsers={handleCheckUsers} checkedUsers={checkedUsers}/>;
        })}
      </TableBody>
    </Table>
  );
};

export default UserTable;
