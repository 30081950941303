import moment from "moment";
import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  CREATE_CUSTOMER_REQUEST_FAIL,
  CREATE_CUSTOMER_REQUEST_SUCCESS,
  DOWNLOAD_CUSTOMER_LIST_FAIL,
  DOWNLOAD_CUSTOMER_LIST_SUCCESS,
  GET_ALL_SUCTOMERS_LIST_SUCCESS,
  GET_ONE_CUSTOMER_FAIL,
  GET_ONE_CUSTOMER_SUCCESS,
} from "../Constants/customerConstants";
import { pageLoader } from "./userActions";

export const createCustomer =
  (userEntry, navigate, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/user/", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: CREATE_CUSTOMER_REQUEST_SUCCESS,
          payload: data?.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const getAllCustomers =
  (setErrorAlert, count, page, search, inputs, fromDate, toDate) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/user/?limit=${count}&page=${page}&search=${search}&location=${inputs.location.join(
          ","
        )}&status=${inputs.user_status.join(",")}&startDate=${
          fromDate ? fromDate : ""
        }&endDate=${toDate ? toDate : ""}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data.success) {
        
        dispatch({
          type: GET_ALL_SUCTOMERS_LIST_SUCCESS,
          payload: data?.data[0],
        });
        dispatch(pageLoader(false));
      }
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const getOneCustomer = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/user/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    if (data.success) {
      dispatch({
        type: GET_ONE_CUSTOMER_SUCCESS,
        payload: data?.data[0],
      });
      dispatch(pageLoader(false));
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const updateCustomer =
  (id, userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.put(`/user/${id}`, userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: CREATE_CUSTOMER_REQUEST_SUCCESS,
          payload: data?.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const deleteCustomer =
  (id, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(`/user/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: CREATE_CUSTOMER_REQUEST_SUCCESS,
          payload: data?.message,
        });
        // dispatch(getAllCustomers(setErrorAlert, 10, 1, ""));
        // setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const exportCustomerList = (setErrorAlert,checkedUsers,setCheckedUsers) => async (dispatch) => {
  try {
    console.log("checkedUsers =",checkedUsers)
    const { data } = await authApi.get(`/user/export/user?id=${checkedUsers?.toString()}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    dispatch({
      type: DOWNLOAD_CUSTOMER_LIST_SUCCESS,
      payload: "Email template downloaded successfully",
    });
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    

    // Create a URL for the blob
    const backendUrl = `${process.env.REACT_APP_API_URL}/user/export/user?id=${checkedUsers?.toString()}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download = "customerReport.xlsx"; // Optional: Set the desired file name
    document.body.appendChild(a);

    // Trigger a click event on the link to initiate the download
    a.click();
    setCheckedUsers([]);
  } catch (error) {
    dispatch({
      type: DOWNLOAD_CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};
