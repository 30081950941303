module.exports = {
  CREATE_CUSTOMER_REQUEST_SUCCESS: "CREATE_CUSTOMER_REQUEST_SUCCESS",
  CREATE_CUSTOMER_REQUEST_FAIL: "CREATE_CUSTOMER_REQUEST_FAIL",
  GET_ALL_SUCTOMERS_LIST_SUCCESS: "GET_ALL_SUCTOMERS_LIST_SUCCESS",
  GET_ALL_SUCTOMERS_LIST_FAIL: "GET_ALL_SUCTOMERS_LIST_FAIL",
  GET_ONE_CUSTOMER_SUCCESS: "GET_ONE_CUSTOMER_SUCCESS",
  GET_ONE_CUSTOMER_FAIL: "GET_ONE_CUSTOMER_FAIL",
  UPDATE_CUSTOMER_REQUEST_SUCCESS: "UPDATE_CUSTOMER_REQUEST_SUCCESS",
  UPDATE_CUSTOMER_REQUEST_FAIL: "UPDATE_CUSTOMER_REQUEST_FAIL",
  DOWNLOAD_CUSTOMER_LIST_SUCCESS: "DOWNLOAD_CUSTOMER_LIST_SUCCESS",
  DOWNLOAD_CUSTOMER_LIST_FAIL: "DOWNLOAD_CUSTOMER_LIST_FAIL",
};
