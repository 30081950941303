import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./popup.css";
import { ColoredNotificationIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect } from "react";

function SendRemainderPop(props) {
  const {
    open,
    handleClose,
    handleRemainderChange,
    remainderDetails,
    handleSendRemainder,
    remainderStatus,
  } = props;

  useEffect(()=>{
      if (remainderStatus?.includes("successfully")) {
        setTimeout(()=>{
            handleClose();
        },2000)
      }
  },[remainderStatus])

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="remainder-pop"
      >
        <Box>
          <Typography className="remainder-header">
            <ColoredNotificationIcon />
            Send Remainder
          </Typography>
          <Box mt={3}>
            <ValidatorForm useref="form" onSubmit={handleSendRemainder}>
              <TextValidator
                variant="outlined"
                multiline
                rows={2}
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Type here.."
                className={`my-field`}
                name="remainderDetails"
                value={remainderDetails}
                size="normal"
                onChange={(e) => handleRemainderChange(e)}
                validators={["required"]} // Add this line
                errorMessages={["Remainder details is required"]}
              />
              <Typography
                mt={1}
                sx={{
                  color: remainderStatus?.includes("successfully")
                    ? "green"
                    : "red",
                }}
              >
                {remainderStatus}
              </Typography>
              <Box
                marginTop="15px"
                display={"flex"}
                alignItems={"center"}
                gap="20px"
              >
                <Button className="cancel-btn" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="send-btn" type="submit">
                  Send
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
          <IoIosCloseCircleOutline
            className={"close-icon"}
            onClick={handleClose}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default SendRemainderPop;
