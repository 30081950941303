import React, { useState } from "react";
import classes from "./auth.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Typography, Button } from "@mui/material";
import DefaultGuestLayout from "./defaultGuestLayout";
import { useNavigate } from "react-router-dom";
import { Numeric } from "../../Utilities/commonFunctions";
import { useDispatch } from "react-redux";
import { pageLoader, verifyOtp } from "../../Redux/Actions/userActions";

const OtpVerify = () => {
  const OtpVerifyDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [focusedElement, setFocusedElement] = useState(null);
    const otp_data = JSON.parse(sessionStorage.getItem("otp_data"));
    const handleSubmit = () => {
      const userEntry = {
        username: otp_data?.username,
        otp: otp,
      };
      dispatch(pageLoader(true));
      dispatch(verifyOtp(userEntry, navigate, setErrorAlert));
    };

    const handleFocus = (name) => {
      setFocusedElement(name);
    };

    const handleBlur = () => {
      setFocusedElement(null);
    };
    return (
      <div className={classes.LoginDivMainContainer}>
        <Typography variant="h3" className={`mb-2 ${classes.LoginMainHeader}`}>
          Verify OTP
        </Typography>
        <Typography variant="h6" className={classes.LoginSubHeader}>
          Enter 6 digit OTP code sent to youEmail ID
        </Typography>
        <Typography variant="h6" className={classes.LoginSubHeader}>
          Otp: {otp_data?.otp}
        </Typography>
        <ValidatorForm
          useref="form"
          className={classes.ValidatorForm}
          onSubmit={handleSubmit}
        >
          <div className={`${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              OTP Code
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              id="otpInput"
              placeholder="Enter OTP Code"
              className={`${classes.formInputFeild} ${
                focusedElement === "otp" && classes.focusedInput
              }`}
              value={otp}
              size="normal"
              onChange={(e) => setOtp(e.target.value)}
              onFocus={() => handleFocus("otp")}
              onBlur={handleBlur}
              onKeyPress={(e) => {
                Numeric(e);
              }}
              inputProps={{ maxLength: 6 }}
              validators={["required"]} // Add this line
              errorMessages={["Please Enter OTP"]}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            className={`mt-5 ${classes.loginBtn}`}
            fullWidth
          >
            Verify
          </Button>
        </ValidatorForm>
      </div>
    );
  };

  return (
    <div>
      <DefaultGuestLayout children={<OtpVerifyDiv />} />
    </div>
  );
};

export default OtpVerify;
