import { FORGOT_PASSWORD_REQUEST_FAIL, FORGOT_PASSWORD_REQUEST_SUCCESS, HEADER_NAME, PAGE_LOADER, RESET_PASSWORD_REQUEST_FAIL, RESET_PASSWORD_REQUEST_SUCCESS, TOGGLE_SIDEBAR, USER_LOGIN_REQUEST_FAIL, USER_LOGIN_REQUEST_SUCCESS, VERIFY_OTP_REQUEST_FAIL, VERIFY_OTP_REQUEST_SUCCESS } from "../Constants/userConstants";

const initialState = {
    loading: false,
    userInfo: null,
    error: null,
    success: null,
    headerName: "dashboard",
    toggleSideBar: false,
}

export const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_LOADER:
            return { ...state, loading: action.payload };
        case TOGGLE_SIDEBAR:
            return { ...state, toggleSideBar: action.payload };
        case HEADER_NAME:
            return { ...state, headerName: action.payload };
        case USER_LOGIN_REQUEST_SUCCESS:
            return { ...state, userInfo: action.payload, error: null };
        case USER_LOGIN_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case FORGOT_PASSWORD_REQUEST_SUCCESS:
            return { ...state, success: action.payload, error: null };
        case FORGOT_PASSWORD_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case VERIFY_OTP_REQUEST_SUCCESS:
            return { ...state, success: action.payload, error: null };
        case VERIFY_OTP_REQUEST_FAIL:
            return { ...state, error: action.payload };
        case RESET_PASSWORD_REQUEST_SUCCESS:
            return { ...state, success: action.payload, error: null };
        case RESET_PASSWORD_REQUEST_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}