import React, { useRef, useState } from "react";
import classes from "../Auth/auth.module.css";
import { Modal, Box, Typography, Button } from "@mui/material";
import { CloseIcon, SuccessGreenChecked } from "../../Assets/Icons/icons";

const VideoModal = ({ modalClose, video }) => {
  const rootRef = useRef(null);
  const [modalToggle, setModalToggle] = useState(true);

  function closeModal(data) {
    modalClose(false);
    setModalToggle(false);
  }

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          className={classes.loginModalWidth}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closebtn}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={`mt-2 ${classes.videoModal}`}>
            <video
              src={video}
              controls
              style={{ width: "100%", height: "100%" }}
            ></video>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoModal;
