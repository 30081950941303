import {
  ADD_PROPERTY_FAIL,
  ADD_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  DELETE_PROPERTY_SUCCESS,
  GET_FILTERS_LIST_SUCCESS,
  GET_ONE_PROPERTY_FAIL,
  GET_ONE_PROPERTY_SUCCESS,
  GET_PROPERTY_FEILD_FAIL,
  GET_PROPERTY_FEILD_SUCCESS,
  GET_PROPERTY_LISTING_SUCCESS,
  PROPERTY_FILTER,
  SELECTED_DATE,
  SEND_REMAINDER,
  TABLE_SORT,
} from "../Constants/propertyConstants";

const initialState = {
  error: null,
  feildsList: null,
  success: null,
  onePropertyDetails: null,
  propertyList: [],
  totalCount: 0,
  filtersList: null,
  remainderStatus: null,
  inputs: {
    category: [],
    property_type: [],
    location: [],
    city: [],
    posted_by: [],
    area: [],
    price: [],
    status: [],
    owner_type: [],
    user: "",
  },
  fromDate: null,
  toDate: null,
  tableSort: "createdAt-desc",
};

export const propertyReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPERTY_FEILD_SUCCESS:
      return { ...state, feildsList: action.payload, error: null };
    case GET_PROPERTY_FEILD_FAIL:
      return { ...state, error: action.payload };
    case ADD_PROPERTY_SUCCESS:
      return { ...state, success: action.payload, error: null };
    case ADD_PROPERTY_FAIL:
      return { ...state, error: action.payload };
    case GET_ONE_PROPERTY_SUCCESS:
      return { ...state, onePropertyDetails: action.payload[0], error: null };
    case GET_ONE_PROPERTY_FAIL:
      return { ...state, error: action.payload };
    case TABLE_SORT:
      return { ...state, tableSort: action.payload, error: null };
    case DELETE_PROPERTY_SUCCESS:
      return { ...state, success: action.payload, error: null };
    case DELETE_PROPERTY_FAIL:
      return { ...state, error: action.payload };
    case GET_PROPERTY_LISTING_SUCCESS:
      return {
        ...state,
        propertyList: action.payload.properties,
        totalCount: action.payload.TotalCount,
        error: null,
      };

    case SELECTED_DATE:
      return {
        ...state,
        fromDate: action.payload[0],
        toDate: action.payload[1],
        error: null,
      };
    case PROPERTY_FILTER:
      return { ...state, inputs: action.payload, error: null };
    case GET_PROPERTY_FEILD_FAIL:
      return { ...state, error: action.payload };
    case GET_FILTERS_LIST_SUCCESS:
      return { ...state, filtersList: action.payload, error: null };
    case SEND_REMAINDER:
      return { ...state, remainderStatus: action.payload, error: null };
    default:
      return state;
  }
};
