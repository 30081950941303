import { Typography } from "@mui/material";
import React from "react";
import classes from "./layout.module.css";
import logo from "../Assets/Images/gazamLogo.png";

const GuestHeaders = () => {
  return (
    <div className={classes.guestHeaderMainDiv}>
      <img src={logo} alt="logo" className={classes.guestHeaderLogo} />
    </div>
  );
};

export default GuestHeaders;
