import {
    api,
    authApi,
    authImageApi,
    getAuthorizationHeader,
  } from "../../Api/api";
  import { pageLoader } from "./userActions";
  
  export const getAllServicesList = (setAllServices,pagination,searchVal) => async (dispatch) => {
    try {
      const { data } = await api.get(`/service-request?page=${pagination}&limit=10&search=${searchVal}`,{
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        setAllServices(data?.data);
      }
    } catch (error) {
        setAllServices([]);
      dispatch(pageLoader(false));
    }
  };

  export const updateServiceStatus =
  (id, userEntry, setUpdateSuccess, setSuccessAlert,handleGetPropertyList) => async (dispatch) => {
    
    try {
      const { data } = await authApi.put(
        `/service-request/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data.success) {
        setUpdateSuccess(true)
        dispatch(pageLoader(false));
       
      }
    } catch (error) {
      
      dispatch(pageLoader(false));
    }
  };

  export const exportServicesList = (checkedServices,setCheckedServices) => async (dispatch) => {
    try {
      console.log("checkedServices =",checkedServices)
      const { data } = await authApi.get(`/service-request/export/servicesform?id=${checkedServices?.toString()}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
 
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      
  
      // Create a URL for the blob
      const backendUrl = `${process.env.REACT_APP_API_URL}/service-request/export/servicesform?id=${checkedServices?.toString()}`;
  
      // Create a link element
      const a = document.createElement("a");
      a.href = backendUrl;
      a.download = "customerReport.xlsx"; // Optional: Set the desired file name
      document.body.appendChild(a);
  
      // Trigger a click event on the link to initiate the download
      a.click();
      setCheckedServices([]);
    } catch (error) {
      
      dispatch(pageLoader(false));
    }
  };