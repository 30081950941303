import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import GuestHeaders from "./headers";
import classes from "./layout.module.css";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const GuestLayout = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (userInfo && userInfo.token) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  useEffect(()=>{
    sessionStorage.removeItem("list_view");
  },[])
  return (
    <div>
      <div className={classes.guestHeaderParentDiv}>
        <GuestHeaders />
      </div>
      <div className={classes.guestBodyParentDiv}>
        <Outlet />
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default GuestLayout;
