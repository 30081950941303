import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import classes from "./user.module.css";
import classesOne from "../Property/propertys.module.css";
import {
  Card,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Pagination,
  Box,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import {
  FileIcon,
  FilterIcon,
  ListFilterIcon,
  NumberInputIcon,
  SearchIcons,
  WhiteSearchIcon,
} from "../../Assets/Icons/icons";
import { Numeric } from "../../Utilities/commonFunctions";
import UserTable from "./userTable";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  exportCustomerList,
  getAllCustomers,
} from "../../Redux/Actions/customerActions";
import {
  getAllFiltersList,
  getFeildsList,
} from "../../Redux/Actions/propertyAction";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import { range } from "../../Utilities/commonConstants";
import moment from "moment";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { CREATE_CUSTOMER_REQUEST_SUCCESS } from "../../Redux/Constants/customerConstants";

let test_status = [
  { _id: "Active", text: "Active" },
  { _id: "Inactive", text: "Inactive" },
  { _id: "Blocked", text: "Blocked" },
];

const UserIndex = () => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState("10");
  const [pagination, setPagination] = useState(1);
  const [inputs, setInputs] = useState({
    location: [],
    user_status: [],
  });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [focusedElement, setFocusedElement] = useState(null);
  const[checkedUsers,setCheckedUsers] = useState([]);
  const { totalCount } = useSelector((state) => state.customerData);
  const { filtersList, feildsList } = useSelector(
    (state) => state.propertyData
  );
  const { success } = useSelector(
    (state) => state.customerData
  );

  const handleChangePagination = (e, count) => {
    setPagination(count);
  };

  const handleChange = async (field, value, list) => {

    if (value.includes("all")) {
      if (inputs[field].length === list.length) {
        setInputs({ ...inputs, [field]: [] });
        return;
      }

      let data;
      if (field === "location" ) {
        data = await list.map((item) => item);
      } else if ( field === "user_status") {
        data = await list.map((item) => item?.text);
      } 
      else {
        data = await list.map((item) => item._id);
      }
      
      setInputs({ ...inputs, [field]: data });
      return;
    }
    setInputs({ ...inputs, [field]: value });
  };
  
  function handleGetUsers() {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        toDate ? dayjs(toDate).format("YYYY-MM-DD") : ""
      )
    );
  }
  console.log("checkedUsers =",checkedUsers)
  const handleClearFilter = async () => {
    const filter = {
      location: [],
      user_status: [],
    };
    setSelectedDate();
    setFromDate();
    setToDate();
    setInputs(filter);
    setSearchValue("");
    // handleGetPropertyList(filter);

    let _inputs = {
      location: [],
      user_status: [],
    };
    let _fromDate = "";
    let _toDate = "";
    let _searchValue = "";
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        _searchValue,
        _inputs,
        _fromDate ? dayjs(_fromDate).format("YYYY-MM-DD") : "",
        _toDate ? dayjs(_toDate).format("YYYY-MM-DD") : ""
      )
    );
  };

  const handleSearch = (e) => {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        toDate ? dayjs(toDate).format("YYYY-MM-DD") : ""
      )
    );
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate,
        toDate
      )
    );
    dispatch(getAllFiltersList(setErrorAlert));
    dispatch(getFeildsList(setErrorAlert));
    dispatch({
      type: CREATE_CUSTOMER_REQUEST_SUCCESS,
      payload: null,
    });
  }, [count, pagination, searchValue,success]);

  
  
  const handleDownloadReport = () => {
    dispatch(pageLoader(true));
    dispatch(exportCustomerList(setErrorAlert,checkedUsers,setCheckedUsers));
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    // dispatch(
    //   getAllProperties(
    //     setErrorAlert,
    //     pagination,
    //     inputs,
    //     tableSort,
    //     moment(picker.startDate._d.toISOString()).format("YYYY-MM-DD"),
    //     moment(picker.endDate._d.toISOString()).format("YYYY-MM-DD")
    //   )
    // );
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <Typography className={classes.userIndexMainHeader}>
        Manage Users
      </Typography>
      <Card className={`mt-4 ${classes.TableContainer}`}>
        <Box p={3} pt={2}>
          <div className={classes.filterHeaderdiv}>
            <Typography className={classes.sub_header}>
              <ListFilterIcon /> Filter
            </Typography>
            <Typography
              className={`${classes.sub_header} ${classes.clearFilterBtn}`}
              onClick={handleClearFilter}
            >
              <FaFilterCircleXmark /> Clear Filter
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth className="form-c selection">
                <InputLabel
                  id="demo-simple-select-label"
                  className={`${classesOne.InputLabel} ${
                    (focusedElement === "location" ||
                      inputs["location"]?.length > 0) &&
                    classesOne.focuedValueLable
                  }`}
                >
                  Location
                </InputLabel>
                <Select
                  variant="outlined"
                  className={`${classesOne.selectInput} ${
                    focusedElement === "location" && classesOne.focusedInput
                  }`}
                  onFocus={() => setFocusedElement("location")}
                  onBlur={() => setFocusedElement(null)}
                  key={"location"}
                  autoComplete="off"
                  select
                  fullWidth
                  id="emailInput"
                  placeholder="Expected by"
                  name={"location"}
                  size="normal"
                  multiple
                  renderValue={(selected) => {
                    if (inputs["location"]?.length === 0) {
                      return (
                        <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                      );
                    }
                    return (
                      <em style={{ textTransform: "capitalize" }}>
                        {inputs["location"]
                          ?.map((value) => {
                            const item = filtersList?.userLocation?.find(
                              (item) => item === value
                            );
                            return item;
                          })
                          .join(", ")}
                      </em>
                    );
                  }}
                  value={inputs["location"]}
                  onChange={(e) =>
                    handleChange(
                      "location",
                      e.target.value,
                      filtersList?.userLocation
                    )
                  }
                  validators={["required"]} // Add this line
                  errorMessages={["Amenities is required"]} // Add this line
                >
                  <MenuItem
                    value={"all"}
                    className={`${classesOne.CheckBoxContainer} selection ${
                      inputs["location"]?.length ===
                        filtersList?.userLocation?.length &&
                      classesOne.CheckBoxActiveSelectAllContainer
                    }`}
                  >
                    <Checkbox
                      sx={{ padding: 0 }}
                      color="primary"
                      checked={
                        inputs["location"]?.length ===
                        filtersList?.userLocation?.length
                      }
                      classesOne={{
                        checked: classesOne.checked,
                      }}
                    />
                    <span>Select All</span>
                  </MenuItem>
                  {filtersList?.userLocation?.map((item) => (
                    <MenuItem
                      value={item}
                      key={item}
                      className={`${classesOne.CheckBoxContainer} selection`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={inputs["location"]?.includes(item)}
                        classesOne={{
                          checked: classesOne.checked,
                        }}
                      />
                      <span
                        className={`${
                          inputs["location"]?.includes(item) &&
                          classesOne.checkboxText
                        }`}
                      >
                        {item}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth className="form-c">
                <InputLabel
                  id="demo-simple-select-label"
                  className={`${classesOne.InputLabel} ${
                    (focusedElement === "user_status" ||
                      inputs["user_status"]?.length > 0) &&
                    classesOne.focuedValueLable
                  }`}
                >
                  User Status
                </InputLabel>
                <Select
                  variant="outlined"
                  className={`${classesOne.selectInput} ${
                    focusedElement === "user_status" && classesOne.focusedInput
                  }`}
                  onFocus={() => setFocusedElement("user_status")}
                  onBlur={() => setFocusedElement(null)}
                  key={"user_status"}
                  autoComplete="off"
                  select
                  fullWidth
                  id="user_status"
                  placeholder="Expected by"
                  name={"user_status"}
                  size="normal"
                  multiple
                  renderValue={(selected) => {
                    if (inputs["user_status"]?.length === 0) {
                      return (
                        <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                      );
                    }
                    return (
                      <em style={{ textTransform: "capitalize" }}>
                        {inputs["user_status"]
                          ?.map((value) => {
                            const item = test_status.find(
                              (item) => item._id === value
                            );
                            return item?.text;
                          })
                          .join(", ")}
                      </em>
                    );
                  }}
                  value={inputs["user_status"]}
                  onChange={(e) =>
                    handleChange("user_status", e.target.value, test_status)
                  }
                  validators={["required"]} // Add this line
                  errorMessages={["Amenities is required"]} // Add this line
                >
                  <MenuItem
                    value={"all"}
                    className={`${classesOne.CheckBoxContainer} selection ${
                      inputs["user_status"]?.length === test_status?.length &&
                      classesOne.CheckBoxActiveSelectAllContainer
                    }`}
                  >
                    <Checkbox
                      sx={{ padding: 0 }}
                      color="primary"
                      checked={
                        inputs["user_status"]?.length === test_status.length
                      }
                      classesOne={{
                        checked: classesOne.checked,
                      }}
                    />
                    <span>Select All</span>
                  </MenuItem>
                  {test_status?.map((item) => (
                    <MenuItem
                      value={item?._id}
                      key={item?._id}
                      className={`${classesOne.CheckBoxContainer} selection`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={inputs["user_status"]?.includes(item?._id)}
                        classesOne={{
                          checked: classesOne.checked,
                        }}
                      />
                      <span
                        className={`${
                          inputs["user_status"]?.includes(item?._id) &&
                          classesOne.checkboxText
                        }`}
                      >
                        {item?.text}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classesOne.datePicker1} style={{ width: "93%" }}>
                <DateRangePicker
                  initialSettings={{ ranges: range }}
                  alwaysShowCalendars={true}
                  onApply={handleEvent}
                >
                  <div>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      autoComplete="off"
                      placeholder="Select Date Period"
                      value={selectedDate ? selectedDate : ""}
                      className={`${
                        selectedDate
                          ? classesOne.dataInputTwo
                          : classesOne.dataInputTwo
                      }`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              <FaRegCalendarAlt />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <span
                      className={classesOne.datePickerCalendarIcon}
                      style={{ right: "10px" }}
                    >
                      <FaRegCalendarAlt />{" "}
                    </span> */}
                  </div>
                </DateRangePicker>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}></Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box display={"flex"} alignItems={"center"} gap={"15px"}>
                {/* <Button
                  className={classes.clear_fil}
                  onClick={handleClearFilter}
                >
                  Clear
                </Button> */}
                <Button className={classes.search_btn} onClick={handleSearch}>
                  <WhiteSearchIcon /> Search
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card className={`mt-4 ${classes.TableContainer}`}>
        <div className={classes.TableHeaderContainer}>
          <div className={classes.TableHeaderLeftContainer}>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="Search"
              className={classes.CommonTextInput}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                      className={classes.eyeIcon}
                    >
                      <SearchIcons />{" "}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.countInputDiv}>
              <Typography className={classes.countText}>Show</Typography>
              <TextField
                // sx={{
                //   "& legend": { display: "none" },
                //   "& fieldset": { top: 0 },
                // }}
                className={classes.countInput}
                value={count}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                // onKeyPress={(e) => {
                //   Numeric(e);
                // }}
                // inputProps={{ maxLength: 2 }}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         aria-label="toggle password visibility"
                //         edge="end"
                //         className={classes.CountIcon}
                //       >
                //         <NumberInputIcon />{" "}
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
                size="small"
                type="number"
              />
              <Typography className={classes.countText}>Entries</Typography>
            </div>
          </div>
          <div className={classes.TableHeaderRightContainer}>
            <Button
              variant="outlined"
              className={classes.userFilterBtn}
              onClick={handleDownloadReport}
            >
              <FileIcon />
              <span>Export to Excel</span>
            </Button>
            {/* <Button variant="outlined" className={classes.userFilterBtn}>
              <FilterIcon />
              <span>View Options</span>
            </Button> */}
          </div>
        </div>
        <div className={classes.TableContainer}>
          <UserTable checkedUsers={checkedUsers} setCheckedUsers={setCheckedUsers} />
        </div>
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(totalCount / parseInt(count))}
            shape="rounded"
            sx={{ textAlign: "end" }}
            onChange={handleChangePagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserIndex;
