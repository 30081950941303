import { Box, Button, Card, Checkbox, IconButton, InputAdornment, Pagination, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "../User/user.module.css";
import classesTwo from "../Property/propertys.module.css";
import { ApproveIcon, FileIcon, SearchIcons, ViewMoreIcon } from "../../Assets/Icons/icons";
import { useDispatch } from "react-redux";
import { exportServicesList, getAllServicesList, updateServiceStatus } from "../../Redux/Actions/serviceActions";
import { MdCheckCircle, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import moment from "moment";
import { getBtnStyles } from "../../Utilities/commonFunctions";

const ServicesIndex = () => {
  const [count, setCount] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [allServices, setAllServices] = useState({services_form:[],total_count:0});
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [singleServiveInfo, setSingleServiveInfo] = useState(null);
  const [pagination, setPagination] = useState(1);
  const[checkedServices,setCheckedServices] = useState([]);

  const dispatch = useDispatch();

  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event,servive_info) => {
    setAnchorEl(event.currentTarget);
    setSingleServiveInfo(servive_info)
  };

  
  const handleChangePagination = (e, count) => {
    setPagination(count);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSingleServiveInfo(null);
  };

  function handleExportServices() {
    dispatch(exportServicesList(checkedServices,setCheckedServices))
  };

  function handleGetAllServices() {
    dispatch(getAllServicesList(setAllServices,pagination,searchValue))
  };

  function handleUpdate() {
    let userEntry = {status: "Completed"};
    if (singleServiveInfo) {
      if (singleServiveInfo?.status === "Completed") {
        userEntry.status = "Pending";
      }
      dispatch(updateServiceStatus(singleServiveInfo?._id,userEntry,setUpdateSuccess));
    }
  };
  console.log("checkedServices =",checkedServices)
  function handleCheckUsers(e,datas) {
    console.log("datas =",datas)
    const {checked} = e.target;
    if (checked) {
      let existing_ids = [...checkedServices];
      existing_ids.push(datas._id);
      setCheckedServices(existing_ids);
    } else {
      let existing_ids = [...checkedServices];
      let filtering_ids = existing_ids.filter((el)=>el != datas._id);
      setCheckedServices(filtering_ids);
    }
  }

  useEffect(()=>{
    if (updateSuccess) {
      setUpdateSuccess(false);
      handleClose();
      handleGetAllServices();
    }
  },[updateSuccess])

  useEffect(()=>{
    handleGetAllServices();
  },[pagination])

  useEffect(()=>{
    setPagination(1);
    handleGetAllServices();
  },[searchValue])


  console.log("singleServiveInfo =",singleServiveInfo)
  return <div>
    <Typography className={classes.userIndexMainHeader}>
        Services
      </Typography>
      <Card className={`mt-4 ${classes.TableContainer}`}>
        <div className={classes.TableHeaderContainer}>
          <div className={classes.TableHeaderLeftContainer}>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="Search"
              className={classes.CommonTextInput}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                      className={classes.eyeIcon}
                    >
                      <SearchIcons />{" "}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <div className={classes.countInputDiv}>
              <Typography className={classes.countText}>Show</Typography>
              <TextField
                className={classes.countInput}
                value={count}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                type="number"
              />
              <Typography className={classes.countText}>Entries</Typography>
            </div> */}
          </div>
          <div className={classes.TableHeaderRightContainer}>
            <Button
              variant="outlined"
              className={classes.userFilterBtn}
              onClick={handleExportServices}
            >
              <FileIcon />
              <span>Export to Excel</span>
            </Button>
          </div>
        </div>
         <div className={classes.TableContainer}>
         <Table>
      <TableHead className={classes.TableHeaderRow}>
        <TableRow>
          <TableCell flex={1} ></TableCell>
          <TableCell flex={1}>
            <span>Service ID</span>
          </TableCell>
          <TableCell flex={1}>
            <span>User Name</span>
          </TableCell>
          <TableCell flex={1}>
            <span>Mobile</span>
          </TableCell>
          <TableCell flex={1} >
            <span>Email ID</span>
          </TableCell>
          <TableCell flex={1}>
            <span>Enquiry date</span>
          </TableCell>
          <TableCell flex={1}>
            <span>Details</span>
            {/* <SortIcon /> */}
          </TableCell>
          <TableCell flex={1}>
            <span>Status</span>
          </TableCell>
          <TableCell flex={1}>
            <span>Action</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody classeName={classes.TableBody}>
        {Array.isArray(allServices?.services_form) &&
        allServices?.services_form?.map((item, index) => {
          return <TableRow className={classes.TableDataRows}>
          <TableCell
            flex={1}
            style={{ minWidth: 72 }}
            className={classes.radioContainer}
          >
            <Checkbox
              icon={<MdOutlineRadioButtonUnchecked />}
              checkedIcon={<MdCheckCircle />}
              // checked={true}
              checked={checkedServices?.includes(item._id)}
              onChange={(e)=>handleCheckUsers(e,item)}
            />
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            {item.services_id.slice(0,6)}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            {item.name}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            {item.phone}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
          {item.email}
            
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
          {moment(item.createdAt).format("DD-M-YYYY")}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 175 }}>
            {item.property_details}
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
            <Button
              className={classes.statusBtn}
              style={getBtnStyles(item.status)}
            >
              {item.status == "Pending" ? item.status : "Contacted" }
            </Button>
          </TableCell>
          <TableCell flex={1} style={{ minWidth: 125 }}>
          <Typography sx={{ cursor: "pointer" }} onClick={(e)=>handleClick(e,item)}>
            <ViewMoreIcon />
          </Typography>
          <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  p={2}
                >
                  <Button
                    className={classesTwo.popper_btns}
                    onClick={handleUpdate}
                  >
                    <ApproveIcon /> Mark as {singleServiveInfo?.status == "Pending" ?"Contacted"  :"Pending" }
                  </Button>
                </Box>
            </Popover>
          </TableCell>
        </TableRow>;
        })}
      </TableBody>
    </Table>
        </div>
      {allServices?.total_count > 0 && <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(allServices?.total_count  / 10)}
            shape="rounded"
            sx={{ textAlign: "end" }}
            onChange={handleChangePagination}
          />
        </div>}
      </Card>
  </div>;
};

export default ServicesIndex;
