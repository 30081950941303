import { styled } from "@emotion/react";

export const Numeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (x > 47 && x < 58) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};
export const Alphabetic = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    x === 46 || // dot "."
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const getBtnStyles = (status) => {
  switch (status) {
    case "Active":
      return {
        backgroundColor: "#E7F7EF",
        color: "#0CAF60",
      };
    case "Inactive":
      return {
        backgroundColor: "#FFF0E6",
        color: "#FE964A",
      };
    case "Blocked":
      return {
        backgroundColor: "#FFF0F0",
        color: "#FD6A6A",
      };
      case "Pending":
        return {
          backgroundColor: "#FFF0E6",
        color: "#FE964A",
        };
        case "Completed":
          return {
            backgroundColor: "#E7F7EF",
            color: "#0CAF60",
          };
    default:
      return {
        backgroundColor: "#007bff",
        color: "#fff",
      };
  }
};

export const dateConversion = (my_date) => {
  const date = new Date(my_date);

const day = date?.getDate().toString().padStart(2, '0'); 
const month = (date?.getMonth() + 1).toString().padStart(2, '0'); 
const year = date?.getFullYear();


const formattedDate = `${day}-${month}-${year}`;
return formattedDate;
};


export const numberWithCommas = (number) => {
  let converted = number?.toString();
  // return converted?.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");


  let reversedString = converted?.split("")?.reverse();
  let storingNewVal = "";
  let commanInsert = 2;
  reversedString?.forEach((val, i) => {
    storingNewVal += val;
    if (commanInsert === i && (i < ( reversedString.length - 1))) {
      commanInsert += 2;
      storingNewVal += ",";
    } else {
    }
  });
  let result = storingNewVal?.split("")?.reverse()?.join("");
  return result;
};