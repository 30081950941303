import {
  api,
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../Api/api";
import {
  ADD_PROPERTY_SUCCESS,
  DELETE_PROPERTY_SUCCESS,
  DOWNLOAD_EXCEL_FILE_SUCCESS,
  GET_FILTERS_LIST_SUCCESS,
  GET_ONE_PROPERTY_FAIL,
  GET_ONE_PROPERTY_SUCCESS,
  GET_PROPERTY_FEILD_FAIL,
  GET_PROPERTY_FEILD_SUCCESS,
  GET_PROPERTY_LISTING_FAIL,
  GET_PROPERTY_LISTING_SUCCESS,
  PROPERTY_FILTER,
  SELECTED_DATE,
  SEND_REMAINDER,
  TABLE_SORT,
  UPLOADS_FILES_SUCCESS,
} from "../Constants/propertyConstants";
import { pageLoader } from "./userActions";

export const getFeildsList = (setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await api.get("/frontend-values/");
    if (data.success) {
      dispatch(pageLoader(false));
      dispatch({
        type: GET_PROPERTY_FEILD_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_FEILD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const uploadsFiles = (files, setErrorAlert) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("file", files);
    const { data } = await authImageApi.post("/property/upload-file", formData);
    if (data.success) {
      return data.data;
    }
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_FEILD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const AddPropertyApi =
  (userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    
    try {
      const { data } = await authApi.post(
        "/property/create-property",
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: ADD_PROPERTY_SUCCESS,
          payload: data?.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: GET_PROPERTY_FEILD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const updatePropertyApi =
  (id, userEntry, setErrorAlert, setSuccessAlert,handleGetPropertyList) => async (dispatch) => {
    
    try {
      const { data } = await authApi.put(
        `/property/update-property/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data.success) {
        if (setSuccessAlert) {
          setSuccessAlert(true);
        }
        dispatch(pageLoader(false));
        dispatch({
          type: ADD_PROPERTY_SUCCESS,
          payload: data?.message,
        });

        if (handleGetPropertyList) {
          handleGetPropertyList();
        }
        dispatch(
          getAllProperties(
            setErrorAlert,
            1,
            {
              category: [],
              property_type: [],
              location: [],
              city: [],
              posted_by: [],
              area: [],
              price: [],
              status: [],
              owner_type: [],
            },
            "createdAt-desc"
          )
        );
      }
    } catch (error) {
      dispatch({
        type: GET_PROPERTY_FEILD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const getOnePropertyDetails =
  (id, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/property/property/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: GET_ONE_PROPERTY_SUCCESS,
          payload: data?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ONE_PROPERTY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const deleteProperty = (id, setErrorAlert,setSuccessAlertDel) => async (dispatch) => {
  try {
    const { data } = await authApi.delete(`/property/delete-property/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    if (data.success) {
      dispatch(pageLoader(false));
      dispatch({
        type: DELETE_PROPERTY_SUCCESS,
        payload: data?.data,
      });
      dispatch(
        getAllProperties(
          setErrorAlert,
          1,
          {
            category: [],
            property_type: [],
            location: [],
            city: [],
            posted_by: [],
            area: [],
            price: [],
            status: [],
            owner_type: [],
          },
          "createdAt-desc"
        )
      );
      if (setSuccessAlertDel) {
        setSuccessAlertDel(true)
      }
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_PROPERTY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const getAllProperties =
  (setErrorAlert, page, inputs, sort, from, to) => async (dispatch) => {
    try {
      dispatch(pageLoader(true));
      const { data } = await authApi.get(
        `/property/all-property?limit=10&page=${page}&location=${
          inputs.location.length > 0 ? inputs.location.join(",") : ""
        }&city=${inputs.city.length > 0 ? inputs.city.join(",") : ""}&status=${
          inputs.status.length > 0 ? inputs.status.join(",") : ""
        }&owner_type=${
          inputs.owner_type.length > 0 ? inputs.owner_type.join(",") : ""
        }&category=${
          inputs.category.length > 0 ? inputs.category.join(",") : ""
        }&property_type=${
          inputs.property_type.length > 0 ? inputs.property_type.join(",") : ""
        }&postedBy=${
          inputs.posted_by.length > 0 ? inputs.posted_by.join(",") : ""
        }&area=${inputs.area.length > 0 ? inputs.area.join(",") : ""}&sort=${
          sort !== "-1" ? sort : "createdAt-desc"
        }&fromDate=${from ? from : ""}&toDate=${to ? to : ""}&user=${
          inputs.user ? inputs.user : ""
        }`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data?.success) {
        dispatch(pageLoader(false));
        dispatch({
          type: GET_PROPERTY_LISTING_SUCCESS,
          payload: data?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PROPERTY_LISTING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };


  export const getAllPropertiesDashboard =
  (setAllproperties,page, status, from, to) => async (dispatch) => {
    try {
      dispatch(pageLoader(true));
      const { data } = await authApi.get(`/property/all-property?limit=10&page=${page}&status=${status.replace(" ","_")}&sort=createdAt-desc  &fromDate=${from}&toDate=${to}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      if (data?.success) {
        setAllproperties(data?.data)
        dispatch(pageLoader(false));
      }
    } catch (error) {

      dispatch(pageLoader(false));
    }
  };
export const downloadExportExcel = (setErrorAlert,checkedProps,setCheckedProps) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/property/export-all-property?id=${checkedProps?.toString()}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch({
      type: DOWNLOAD_EXCEL_FILE_SUCCESS,
      payload: "Email template downloaded successfully",
    });

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });



    // Create a URL for the blob
    const backendUrl = `${process.env.REACT_APP_API_URL}/property/export-all-property?id=${checkedProps?.toString()}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download = "studentsTemplate.xlsx"; // Optional: Set the desired file name
    document.body.appendChild(a);
    setCheckedProps([]);
    // Trigger a click event on the link to initiate the download
    a.click();

    dispatch(pageLoader(false));
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_LISTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const getAllFiltersList = (setErrorAlert, page) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/property/filter-details`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    if (data?.success) {
      dispatch({
        type: GET_FILTERS_LIST_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_LISTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const sendPropertyRemainder = (userEntry) => async (dispatch) => {
  try {
    const { data } = await authApi.post(
      `/property/notification/property-reminder`,
      userEntry,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    if (data?.success) {
      // dispatch(pageLoader(false));
      dispatch({
        type: SEND_REMAINDER,
        payload: data?.message,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_REMAINDER,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    // setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const saveInputFilter = (inputs) => async (dispatch) => {
  dispatch({ type: PROPERTY_FILTER, payload: inputs });
};

export const saveSelectedDate = (from, to) => async (dispatch) => {
  dispatch({ type: SELECTED_DATE, payload: [from, to] });
};

export const saveSortOrder = (value) => async (dispatch) => {
  dispatch({ type: TABLE_SORT, payload: value });
};

export const downloadPropertyDocs =
  (id) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/property/user-document/download/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      // if (data.success) {
        console.log("428")
        let url = `
https://api.demo.gazam.apps.org.in/api/property/user-document/download/${id}`
        window.open(url, '_blank')
      // }
    } catch (error) {
      
      dispatch(pageLoader(false));
    }
  };

  export const adminDashboardCounts =
  (from,to,setCounts) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/property/admin-dashboard?start_date=${from}&end_date=${to}&status=Active`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      if (data.success) {
        setCounts(data?.data);
      }
    } catch (error) {
      setCounts(null);
      dispatch(pageLoader(false));
    }
  };

  export const userNotifications = ( _id, _token,setNotifications) => async (dispatch) => {
    try {
        const { data } = await api.get(`notification/get-user-notifications/${_id}`, {
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        })
        if (data.success) {
            let arrays = data?.data;

            let _sorted = arrays?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setNotifications(_sorted);
        }
    }
    catch (error) {
        setNotifications([]);
        // localStorage.setItem("error","2");
        // dispatch({
        //     type: ERROR_ALERT,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message
        // })
        
        dispatch(pageLoader(false));
    }
}

export const updateUserNotifications = ( userEntry,datass,handleUserNotificatios) => async (dispatch) => {
    try {
        const { data } = await api.patch(`notification/update-notification/${datass?._id}`, userEntry,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
                handleUserNotificatios();
            
        }
    }
    catch (error) {
        
        // localStorage.setItem("error","2");
        // dispatch({
        //     type: ERROR_ALERT,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message
        // })
        
        dispatch(pageLoader(false));
    }
}

export const deleteUserNotifications = ( _id,handleUserNotificatios) => async (dispatch) => {
    try {
        const { data } = await api.delete(`notification/delete-notification/${_id}`,{
            headers: {
                Authorization: getAuthorizationHeader()
            }
        })
        if (data.success) {
            handleUserNotificatios();
        }
    }
    catch (error) {
        
        // localStorage.setItem("error","2");
        // dispatch({
        //     type: ERROR_ALERT,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message
        // })
        
        dispatch(pageLoader(false));
    }
}