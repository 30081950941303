import React from "react";
import classes from "./auth.module.css";

const DefaultGuestLayout = ({ children }) => {
  return (
    <div className={classes.defaultGuestLayoutMaindiv}>
      <div className={classes.loginDefaultLayoutChildContainer}>{children}</div>
      <div className={classes.DefaultGuestLayoutRightContainer}></div>
    </div>
  );
};

export default DefaultGuestLayout;
