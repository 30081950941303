import React, { useState } from "react";
import classes from "./auth.module.css";
import DefaultGuestLayout from "./defaultGuestLayout";
import { Typography, Button, InputAdornment, IconButton } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser, pageLoader } from "../../Redux/Actions/userActions";

const Login = () => {
  const LoginDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [focusedElement, setFocusedElement] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = () => {
      const userEntry = {
        username: email,
        password: password,
      };
      dispatch(pageLoader(true));
      dispatch(loginUser(userEntry, navigate, setErrorAlert));
    };

    const handleFocus = (name) => {
      setFocusedElement(name);
    };

    const handleBlur = () => {
      setFocusedElement(null);
    };

    return (
      <div className={classes.LoginDivMainContainer}>
        <Typography variant="h3" className={`mb-2 ${classes.LoginMainHeader}`}>
          Welcome to Gazam
        </Typography>
        <Typography variant="h6" className={classes.LoginSubHeader}>
          Please enter your Email/Username to continue
        </Typography>
        <ValidatorForm
          useref="form"
          className={classes.ValidatorForm}
          onSubmit={handleSubmit}
        >
          <div className={`${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              Email/Username
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              id="emailMobileInput"
              placeholder="Enter E-mail ID/Username"
              className={`${classes.formInputFeild} ${
                focusedElement === "email" && classes.focusedInput
              }`}
              value={email}
              size="normal"
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => handleFocus("email")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Mobile Number or Email is required"]}
            />
          </div>
          <div className={`mt-4 ${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              Password
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="passwordInput"
              placeholder="Enter your password"
              className={`${classes.formInputFeild} ${
                focusedElement === "password" && classes.focusedInput
              }`}
              value={password}
              size="normal"
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => handleFocus("password")}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className={classes.eyeIcon}
                    >
                      {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              validators={["required"]} // Add this line
              errorMessages={["Password is required"]}
            />
          </div>
          <Typography
            className={`mt-2 ${classes.forgotPasswordBtn}`}
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </Typography>
          <Button
            type="submit"
            variant="contained"
            className={`mt-5 ${classes.loginBtn}`}
            fullWidth
          >
            Login
          </Button>
        </ValidatorForm>
      </div>
    );
  };
  return <DefaultGuestLayout children={<LoginDiv />} />;
};

export default Login;
