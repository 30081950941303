import {
  CREATE_CUSTOMER_REQUEST_FAIL,
  CREATE_CUSTOMER_REQUEST_SUCCESS,
  GET_ALL_SUCTOMERS_LIST_FAIL,
  GET_ALL_SUCTOMERS_LIST_SUCCESS,
  GET_ONE_CUSTOMER_FAIL,
  GET_ONE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_REQUEST_FAIL,
  UPDATE_CUSTOMER_REQUEST_SUCCESS,
} from "../Constants/customerConstants";

const initialState = {
  success: null,
  error: null,
  customersList: [],
  oneCustomer: null,
  totalCount: 0,
};

export const customerReducers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST_SUCCESS:
      return { ...state, success: action.payload, error: null };
    case CREATE_CUSTOMER_REQUEST_FAIL:
      return { ...state, error: action.payload };
    case GET_ALL_SUCTOMERS_LIST_SUCCESS:
      return {
        ...state,
        customersList: action.payload.customers,
        error: null,
        totalCount: action.payload.totalCount,
      };
    case GET_ALL_SUCTOMERS_LIST_FAIL:
      return { ...state, error: action.payload };
    case GET_ONE_CUSTOMER_SUCCESS:
      return { ...state, oneCustomer: action.payload };
    case GET_ONE_CUSTOMER_FAIL:
      return { ...state, error: action.payload };
    case UPDATE_CUSTOMER_REQUEST_SUCCESS:
      return { ...state, success: action.payload, error: null };
    case UPDATE_CUSTOMER_REQUEST_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
