module.exports = {
  USER_LOGIN_REQUEST_SUCCESS: "USER_LOGIN_REQUEST_SUCCESS",
  USER_LOGIN_REQUEST_FAIL: "USER_LOGIN_REQUEST_FAIL",
  HEADER_NAME: "HEADER_NAME",
  PAGE_LOADER: "PAGE_LOADER",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  FORGOT_PASSWORD_REQUEST_SUCCESS: "FORGOT_PASSWORD_REQUEST_SUCCESS",
  FORGOT_PASSWORD_REQUEST_FAIL: "FORGOT_PASSWORD_REQUEST_FAIL",
  VERIFY_OTP_REQUEST_SUCCESS: "VERIFY_OTP_REQUEST_SUCCESS",
  VERIFY_OTP_REQUEST_FAIL: "VERIFY_OTP_REQUEST_FAIL",
  RESET_PASSWORD_REQUEST_SUCCESS: "RESET_PASSWORD_REQUEST_SUCCESS",
  RESET_PASSWORD_REQUEST_FAIL: "RESET_PASSWORD_REQUEST_FAIL",
};
