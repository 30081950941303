import React, { useState } from "react";
import classes from "./auth.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Typography, Button } from "@mui/material";
import DefaultGuestLayout from "./defaultGuestLayout";
import { useNavigate } from "react-router-dom";
import { Numeric } from "../../Utilities/commonFunctions";
import { useDispatch } from "react-redux";
import { forgotPassword, pageLoader } from "../../Redux/Actions/userActions";

const ForgotPassword = () => {
  const ForgotPasswordDiv = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [focusedElement, setFocusedElement] = useState(null);

    const handleSubmit = () => {
      const userEntry = {
        username: email,
      };
      dispatch(pageLoader(true));
      dispatch(forgotPassword(userEntry, navigate, setErrorAlert));
    };

    const handleFocus = (name) => {
      setFocusedElement(name);
    };

    const handleBlur = () => {
      setFocusedElement(null);
    };
    return (
      <div className={classes.LoginDivMainContainer}>
        <Typography variant="h3" className={`mb-2 ${classes.LoginMainHeader}`}>
          Forgot Password ?
        </Typography>
        <Typography variant="h6" className={classes.LoginSubHeader}>
          Please enter your registered email id so we’ll send a link to reset it
        </Typography>
        <ValidatorForm
          useref="form"
          className={classes.ValidatorForm}
          onSubmit={handleSubmit}
        >
          <div className={`${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              Email ID
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              id="emailMobileInput"
              placeholder="Enter E-mail ID"
              className={`${classes.formInputFeild} ${
                focusedElement === "email" && classes.focusedInput
              }`}
              value={email}
              size="normal"
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => handleFocus("email")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Please Enter Email"]}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            className={`mt-5 ${classes.loginBtn}`}
            fullWidth
          >
            Sent OTP
          </Button>
        </ValidatorForm>
      </div>
    );
  };

  return (
    <div>
      <DefaultGuestLayout children={<ForgotPasswordDiv />} />
    </div>
  );
};

export default ForgotPassword;
