import React, { useState } from "react";
import Dropzone from "react-dropzone";
import classes from "./property.module.css";
import { Button, Typography } from "@mui/material";
import { ImageIcon } from "../../Assets/Icons/icons";

const DragAndDrops = ({ images, onDropFunction, accept }) => {
  const onDrop = (acceptedFiles) => {
    try {
      const newArray = [...acceptedFiles];
      onDropFunction(newArray);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <div>
      <Dropzone onDrop={onDrop} accept={accept}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classes.propertyImageUploadContainer,
            })}
          >
            <input {...getInputProps()} accept={accept} />
            <Button
              variant="contained"
              className={classes.propertyImageUploadBtn}
            >
              <ImageIcon />
              <span>Choose file</span>
            </Button>
            <Typography className={classes.propertyImageUploadText}>
              Or Drag to upload
            </Typography>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default DragAndDrops;
