import React, { useEffect, useState } from "react";

const DummyPage = () => {
  const [queryData, setQueryData] = useState({});

  useEffect(() => {
    const DummyData = JSON.parse(sessionStorage.getItem("QueryData"));
    setQueryData(DummyData);
  }, [queryData]);

  return (
    <div>
      <h1>Hello, {queryData.name}!</h1>
      <p>You are {queryData.age} years old.</p>
    </div>
  );
};

export default DummyPage;
