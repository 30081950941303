import { Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import classes from "./layout.module.css";
import logo from "../Assets/Images/gazamLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName, setToggleSidebar } from "../Redux/Actions/userActions";
import {
  AddPropertyIcon,
  AddUserIcon,
  MenuBarIcons,
  NotificationIcon,
} from "../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import NotificationPop from "../Pages/popups/NotificationPop";
import { deleteUserNotifications, userNotifications } from "../Redux/Actions/propertyAction";

const DefaultHeaders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const _token = JSON.parse(localStorage.getItem("userToken"));
  const { headerName, toggleSideBar } = useSelector((state) => state.userLogin);

  const handleShow = () => {
    console.log(!toggleSideBar);
    dispatch(setToggleSidebar(!toggleSideBar));
    setShow(true);
  };

  function handleUpdateNotification(_id) {
    let userEntry = {
      seen: true,
    };
    dispatch(deleteUserNotifications(_id, handleUserNotificatios));
  }

  function handleUserNotificatios() {
    if (_token) {
      dispatch(userNotifications(_token?.id, _token?.token, setNotifications));
    }
  }

    
  function handleNotificationNums() {
    if (notifications) {
      let all_notifications = [...notifications];

      let unseen_notifications = all_notifications?.filter((el)=>el.seen === false);

      return unseen_notifications?.length;
      
    } else {
      return 0;
    }

  }

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(setHeaderName(arr[1]));
    handleUserNotificatios();
  }, [window.location.pathname]);

  return (
    <div
      className={`${classes.guestHeaderMainDiv} ${classes.defaultHeaderMainDiv}`}
    >
      {/* <Button variant="text" onClick={handleShow} style={{ minWidth: 0 }}>
        <MenuBarIcons />
      </Button> */}
      <div onClick={handleShow} className={classes.defaultHeaderLeftDiv}>
        <Typography className={classes.headerMainText}>{headerName}</Typography>
        {window.location.pathname.includes("dashboard") && (
          <Typography className={`mt-1 ${classes.headerSubText}`}>
            Welcome !
          </Typography>
        )}
      </div>
      <div className={classes.defaultHeaderRightDiv}>
        <Button variant="text" className={classes.notificationIcon} onClick={()=>setNotificationPopup(true)}>
          <NotificationIcon />
          <span className={`${classes.notification_count}`}>
                  {handleNotificationNums() > 0 ? handleNotificationNums() : ""}
                </span>
        </Button>
        <Button
          variant="outlined"
          className={classes.headerRightBtns}
          onClick={() => navigate("/property/add-property")}
        >
          <AddPropertyIcon />
          <span>+ Add Property</span>
        </Button>
        <Button
          variant="outlined"
          className={classes.headerRightBtns}
          onClick={() => navigate("/add-users")}
        >
          <AddUserIcon />
          <span>+ Add Users</span>
        </Button>
      </div>
      <NotificationPop
              open={notificationPopup}
              handleClose={() => setNotificationPopup(false)}
              notifications={notifications}
              handleUpdateNotification={handleUpdateNotification}
              handleUserNotificatios={handleUserNotificatios}
      />
    </div>
  );
};

export default DefaultHeaders;
