import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducers } from "./Reducers/userReducers";
import { customerReducers } from "./Reducers/customerReeducers";
import { propertyReducers } from "./Reducers/propertyReducers";

const reducer = combineReducers({
  userLogin: userReducers,
  customerData: customerReducers,
  propertyData: propertyReducers,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage, toggleSideBar: false },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
