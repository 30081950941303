module.exports = {
  GET_PROPERTY_FEILD_SUCCESS: "GET_PROPERTY_FEILD_SUCCESS",
  GET_PROPERTY_FEILD_FAIL: "GET_PROPERTY_FEILD_FAIL",
  UPLOADS_FILES_SUCCESS: "UPLOADS_FILES_SUCCESS",
  UPLOADS_FILES_FAIL: "UPLOADS_FILES_FAIL",
  ADD_PROPERTY_SUCCESS: "ADD_PROPERTY_SUCCESS",
  ADD_PROPERTY_FAIL: "ADD_PROPERTY_FAIL",
  GET_ONE_PROPERTY_SUCCESS: "GET_ONE_PROPERTY_SUCCESS",
  GET_ONE_PROPERTY_FAIL: "GET_ONE_PROPERTY_FAIL",
  UPDATE_PROPERTY_SUCCESS: "UPDATE_PROPERTY_SUCCESS",
  UPDATE_PROPERTY_FAIL: "UPDATE_PROPERTY_FAIL",
  DELETE_PROPERTY_SUCCESS: "DELETE_PROPERTY_SUCCESS",
  DELETE_PROPERTY_FAIL: "DELETE_PROPERTY_FAIL",
  GET_PROPERTY_LISTING_SUCCESS: "GET_PROPERTY_LISTING_SUCCESS",
  GET_PROPERTY_LISTING_FAIL: "GET_PROPERTY_LISTING_FAIL",
  DOWNLOAD_EXCEL_FILE_SUCCESS: "DOWNLOAD_EXCEL_FILE_SUCCESS",
  DOWNLOAD_EXCEL_FILE_FAIL: "DOWNLOAD_EXCEL_FILE_FAIL",
  GET_FILTERS_LIST_SUCCESS: "GET_FILTERS_LIST_SUCCESS",
  GET_FILTERS_LIST_FAIL: "GET_FILTERS_LIST_FAIL",
  SEND_REMAINDER: "SEND_REMAINDER",
  SEND_REMAINDER_SUCCESS: "SEND_REMAINDER_SUCCESS",
  PROPERTY_FILTER: "PROPERTY_FILTER",
  SELECTED_DATE: "SELECTED_DATE",
  TABLE_SORT: "TABLE_SORT",
};
