import React, { useState, useEffect } from "react";
import classes from "./layout.module.css";
import mainLogo from "../Assets/Images/mainLogo.png";
import smallLogo from "../Assets/Images/smallLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setHeaderName } from "../Redux/Actions/userActions";
import { Button } from "@mui/material";
import {
  DashboardIcon,
  LogoutIcon,
  PlanIcon,
  PropertyIcon,
  ServiceIcon,
  UserIcon,
} from "../Assets/Icons/icons";
import ConfirmModal from "../Components/confirmModel";

const SideNavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, toggleSideBar, showLogoutModal } = useSelector(
    (state) => state.userLogin
  );
  const [currentRoute, setCurrentRoute] = useState();
  const [logoutModal, setLogoutModal] = useState(false);

  useEffect(() => {
    setCurrentRoute(window.location.pathname.toLowerCase().split("/")[1]);
  }, [window.location.pathname]);

  const handleNavigate = (name, route) => {
    dispatch(setHeaderName(name));
    setCurrentRoute(route);
  };

  const closeModal = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    closeModal();
    navigate("/login");
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div>
        {toggleSideBar ? (
          <img
            src={mainLogo}
            alt="Metaverse Logo"
            className={`${classes.imageLogo} ${classes.sidenavLogoImage}`}
          />
        ) : (
          <img
            src={smallLogo}
            alt="Metaverse Logo"
            className={`${classes.smallImageLogo} ${classes.sidenavLogoImage}`}
          />
        )}
      </div>
      <div className={`mt-3 ${classes.sidenavListMainCointainer}`}>
        <div
          className={`mt-2 ${classes.nabBarContainer}`}
          // style={{ minHeight: "750px" }}
        >
          <Link
            to="/dashboard"
            className={
              currentRoute?.includes("dashboard") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("dashboard", "/dashboard")}
          >
            <li>
              <Button
                variant="text"
                className={`${classes.linkburron} ${
                  !toggleSideBar && classes.ToggledButton
                }`}
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <DashboardIcon />
                </span>{" "}
                {toggleSideBar ? (
                  <span className={classes.sidebarText}>Dashboard</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/users"
            className={
              currentRoute?.includes("users") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("users", "/users")}
          >
            <li>
              <Button
                variant="text"
                className={`${classes.linkburron} ${
                  !toggleSideBar && classes.ToggledButton
                }`}
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <UserIcon />
                </span>{" "}
                {toggleSideBar ? (
                  <span className={classes.sidebarText}>Users</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/property"
            className={
              currentRoute?.includes("property") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("property", "/property")}
          >
            <li>
              <Button
                variant="text"
                className={`${classes.linkburron} ${
                  !toggleSideBar && classes.ToggledButton
                }`}
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <PropertyIcon />
                </span>{" "}
                {toggleSideBar ? (
                  <span className={classes.sidebarText}>Property</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/services"
            className={
              currentRoute?.includes("services") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("services", "/services")}
          >
            <li>
              <Button
                variant="text"
                className={`${classes.linkburron} ${
                  !toggleSideBar && classes.ToggledButton
                }`}
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <ServiceIcon />
                </span>{" "}
                {toggleSideBar ? (
                  <span className={classes.sidebarText}>Service</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/plans"
            className={
              currentRoute?.includes("plans") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("plans", "/plans")}
          >
            <li>
              <Button
                variant="text"
                className={`${classes.linkburron} ${
                  !toggleSideBar && classes.ToggledButton
                }`}
              >
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <PlanIcon />
                </span>{" "}
                {toggleSideBar ? (
                  <span className={classes.sidebarText}>Plans</span>
                ) : null}
              </Button>
            </li>
          </Link>
        </div>
        {/* <div className={classes.ProfileManagementBtnsContainer}>
          <Button
            variant="text"
            className={classes.linkburron}
            onClick={() => {
              handleLogoutFunction();
            }}
          >
            <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
              <LogoutIcon />
            </span>{" "}
            {toggleSideBar || device === "mobile" ? (
              <span className={classes.sidebarText}>Logout</span>
            ) : null}
          </Button>
          <Button
            variant="outlined"
            className={
              toggleSideBar || device === "mobile"
                ? `d-flex align-items-center justify-content-between ${classes.ProfileBtn}`
                : `d-flex align-items-center justify-content-between ${classes.ProfileBtnSmall}`
            }
          >
            <div className="d-flex align-items-center">
              <Avatar alt="Remy Sharp" src={profileIconImage} />
              {toggleSideBar || device === "mobile" ? (
                <Typography className={classes.ProfileAvatarBtnText}>
                  Admin
                </Typography>
              ) : null}
            </div>
            {toggleSideBar || device === "mobile" ? (
              <span>
                <ProfileNavIcon />
              </span>
            ) : null}
          </Button>
        </div> */}
      </div>
      <div
        className={`${classes.logoutBtnContainer} ${classes.nabBarContainer}`}
      >
        <Link to="#" onClick={() => setLogoutModal(true)}>
          <li>
            <Button
              variant="text"
              className={`${classes.linkburron} ${
                !toggleSideBar && classes.ToggledButton
              }`}
            >
              <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
                <LogoutIcon />
              </span>{" "}
              {toggleSideBar ? (
                <span className={classes.sidebarText}>Logout</span>
              ) : null}
            </Button>
          </li>
        </Link>
      </div>

      {logoutModal && (
        <ConfirmModal
          modalClose={closeModal}
          heading={"Logout Confirmation"}
          subHeading={`Are you sure want to Logout`}
          callback={handleLogout}
        />
      )}
    </div>
  );
};

export default SideNavBar;
