import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./popup.css";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React, { useState, useCallback, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const containerStyle = {
  width: "100%",
  height: "370px",
};

function GoogleMapPop(props) {
  const { open, handleClose,isLoaded,setMap,marker,centerPoint, setCenterPoint ,handleClick,handleAddMaps} = props;
  const [isMounted, setIsMounted] = useState(false);
  const [value, setValue] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleSelect = async (value) => {
    setValue(value);
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    // setSearchCoordinates(latLng);
    setCenterPoint(latLng);
  };

  function handleClearField() {
    setValue(null);
  }

  useEffect(() => {
    
    // let my_res = JSON.parse(sessionStorage.getItem("property_info"));

    // if (my_res?.property_cordinates?.coordinates) {
    //   setCenterPoint({lat: my_res?.property_cordinates?.coordinates[0],lng:my_res?.property_cordinates?.coordinates[1]})
    // } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          
          const new_center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenterPoint(new_center);
        });
      } else {
        alert("Geolocation is not available in your browser.");
      }
    // }
  }, []);
  // console.log("marker =",marker)
  // console.log("marker.lat || marker.Lat =",marker.lat ,"||", marker.Lat)
  
  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="google-map"
      >
        <Box>
          <Box className="d-flex-between popup-header">
            <Typography fontSize={"20px"}>Select your location</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
          <Box p={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Box className="d-flex" gap={"15px"} >
              <Box  width={"400px"}>
              <GooglePlacesAutocomplete
            selectProps={{
              value,
              onChange: handleSelect,
              placeholder: 'Search for a place...',
            }}
          />
              </Box>
              {/* <GoogleAutocomplete
          onLoad={handleLoad}
          // onPlaceChanged={handlePlaceChanged}
        >
              <TextField
                    inputRef={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Search for your location"
                    className="loc-search-field"
                  />
                   </GoogleAutocomplete> */}
                  {value &&
                    <Button
                size="small"
                className={"clear-btn"}
                  onClick={handleClearField}
              >
                clear
              </Button>}
              </Box>
              <Button
                size="small"
                className={"capture-btn"}
                  onClick={handleAddMaps}
              >
                Add
              </Button>
            </Box>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={centerPoint}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={handleClick}
              >
                {marker  && (
                  <Marker position={{ lat: marker.lat || marker.Lat, lng: marker.lng || marker.Lng}} />
                )}
              </GoogleMap>
            ) : (
              <Typography>Not found</Typography>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default GoogleMapPop;
