import { api, authApi } from "../../Api/api";
import {
  HEADER_NAME,
  PAGE_LOADER,
  TOGGLE_SIDEBAR,
  USER_LOGIN_REQUEST_FAIL,
  USER_LOGIN_REQUEST_SUCCESS,
  VERIFY_OTP_REQUEST_FAIL,
  VERIFY_OTP_REQUEST_SUCCESS,
} from "../Constants/userConstants";

export const loginUser =
  (userEntry, navigate, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/auth/login/admin", userEntry);
      
      localStorage.setItem("userToken", JSON.stringify(data?.data[0]));
      dispatch({
        type: USER_LOGIN_REQUEST_SUCCESS,
        payload: data?.data[0],
      });
      dispatch(pageLoader(false));
      navigate("/dashboard");
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const setHeaderName = (name) => async (dispatch) => {
  dispatch({
    type: HEADER_NAME,
    payload: name,
  });
};

export const pageLoader = (value) => async (dispatch) => {
  dispatch({
    type: PAGE_LOADER,
    payload: value,
  });
};

export const setToggleSidebar = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: value,
  });
};

export const forgotPassword =
  (userEntry, navigate, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/auth/forget_password", userEntry);
      if (data?.success) {
        sessionStorage.setItem("otp_data", JSON.stringify(data?.data[0]));
        dispatch({
          type: USER_LOGIN_REQUEST_SUCCESS,
          payload: data?.data[0],
        });
        dispatch(pageLoader(false));
        navigate("/otp-verify");
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const verifyOtp =
  (userEntry, navigate, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/auth/verify_otp", userEntry);
      if (data?.success) {
        sessionStorage.setItem(
          "change_password_data",
          JSON.stringify(data?.data[0])
        );
        
        // dispatch({
        //     type: VERIFY_OTP_REQUEST_SUCCESS,
        //     payload: data?.data[0],
        // });
        dispatch(pageLoader(false));
        navigate("/change-password");
      }
    } catch (error) {
      dispatch({
        type: VERIFY_OTP_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const ResetPassword =
  (userEntry, navigate, setErrorAlert) => async (dispatch) => {
    try {
      const authToken = sessionStorage.getItem("change_password_token");
      
      const { data } = await api.post("/auth/reset_password", userEntry, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (data?.success) {
        dispatch(pageLoader(false));
        navigate("/login");
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };
