import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Layout/guestLayout";
import Login from "./Pages/Auth/login";
import Home from "./Pages/Home/home";
import DefaultLayout from "./Layout/defaultLayout";
import ForgotPassword from "./Pages/Auth/forgotPassword";
import OtpVerify from "./Pages/Auth/otpVerify";
import ChangePassword from "./Pages/Auth/changePassword";
import UserIndex from "./Pages/User";
import PropertyIndex from "./Pages/Property";
import ServicesIndex from "./Pages/Services";
import PlansIndex from "./Pages/Plans";
import AddUsers from "./Pages/User/addUsers";
import AddProperty from "./Pages/Property/addProperty";
import PropertyViewScreen from "./Pages/Property/PropertyViewScreen";
import DummyPage from "./Pages/Property/dummyPage";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/otp-verify",
        element: <OtpVerify />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Home />,
      },
      {
        path: "/users",
        element: <UserIndex />,
      },
      {
        path: "/add-users",
        element: <AddUsers />,
      },
      {
        path: "/update-user/:id",
        element: <AddUsers />,
      },
      {
        path: "/property",
        element: <PropertyIndex />,
      },
      {
        path: "/property/add-property",
        element: <AddProperty />,
      },
      {
        path: "/property/edit-property/:id",
        element: <AddProperty />,
      },
      {
        path: "/property/view-property/:id",
        element: <AddProperty />,
      },
      {
        path: "/dummy",
        element: <DummyPage />,
      },
      {
        path: "/services",
        element: <ServicesIndex />,
      },
      {
        path: "/plans",
        element: <PlansIndex />,
      },
    ],
  },
]);

export default Router;
