import styled from "@emotion/styled";
import { TextValidator } from "react-material-ui-form-validator";

export const StyledInput = styled(TextValidator)`
  &:focus {
    /* Add your focused styles here */
    border-bottom: green; /* Change border color to green on focus */
    box-shadow: 0 0 5px rgba(0, 128, 0, 0.5); /* Change box shadow color to green */
  }

  /* Change label color to green */
  label.Mui-focused {
    color: var(--text-color-1) !important;
  }

  &.Mui.focused {
    border-color: green !important;
  }
`;
